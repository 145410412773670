<template>
    <div class="main-wrapper">
      <router-view></router-view>
    </div>
</template>
<script>
export default {
  name: 'AuthLayout'
}
</script>
